import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { motion } from 'framer-motion';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import analytics, { ConsentBanner, initAnalytics, logLIConverstion } from './app/analytics';
import AppProviders, { ErrorFallback } from './app/appProviders';
import EnsureUnAuth from './components/account/ensureUnAuth';
import RequireAuth from './components/account/requireLogin';
import WaitForInitialization from './components/account/waitForInitialization';
import { CalendarButton } from './components/Calendar';
import CalendarRawButton from './components/Calendar/calendarRawButton';
import FlowCell from './components/FlowCell';
import Loading from './components/Loading';
import ViewPartProvider from './components/ViewPart/viewPartProvider';
import reportWebVitals from './reportWebVitals';

const ActionPage = React.lazy(() => import('./components/account/confirm'));
const Cart = React.lazy(() => import('./components/Cart'));
const CookiePage = React.lazy(() => import('./components/CookiesPage'));
const EmailListForm = React.lazy(() => import('./components/EmailListForm'));
const Navigation = React.lazy(() => import('./components/Navigation'));
const PartList = React.lazy(() => import('./components/PartList'));
const PaywallModal = React.lazy(() => import('./components/PaywallModal'));
const ResetPasswordForm = React.lazy(() => import('./components/account/resetPassword'));
const SignInForm = React.lazy(() => import('./components/account/signIn'));
const SignUpForm = React.lazy(() => import('./components/account/signUp'));
const Testimonials = React.lazy(() => import('./components/Testimonials'));
const UploadPart = React.lazy(() => import('./components/UploadPart'));
const ViewPart = React.lazy(() => import('./components/ViewPart'));
const ViewQuoteRedirectPage = React.lazy(() => import('./components/ViewQuoteRedirect'));
const stripe = loadStripe(process.env.REACT_APP_STRIPE_SECRET);
const elementsOptions = {
  // custom css to make stripe input looks like MUI inputs
  appearance: {
    theme: 'night',
    variables: {
      colorBackground: 'rgb(21, 27, 34)',
      colorText: '#c5cfd9',
      borderRadius: '8px',
    },
    rules: {
      '.Input': {
        padding: '16.5px 14px',
        color: '#c5cfd9',
        backgroundColor: 'rgb(21, 27, 34)',
        border: '1px solid rgb(33, 38, 45)',
      },
      '.Input:focus': {
        color: '#acb8c4',
        backgroundColor: 'rgb(31, 42, 55)',
        border: '1px solid rgb(57, 66, 80)',
        boxShadow: 'none',
      },
    },
  },
};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  if ([
    'account',
    'action',
    'careers',
    'cart',
    'detail_content',
    'index',
    'flow-cell',
    'legal',
    'parts',
    'thank-you',
    'upload',
    'view-quote',
    'home-copy',
    'landing-pages',
  ].includes(window.location.pathname.split('/')[1]) && !window.location.pathname.endsWith('.html')) {
    let { pathname } = window.location;
    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }
    window.location.href = `${window.location.origin + pathname}.html${window.location.search}`;
  }
}

window.Webflow = window.Webflow || [];
window.startReact = () => {
  // start Sentry tracing
  Sentry.init({
    dsn: 'https://e070f3b55dd24ae7b6bc9095bb78c154@o507646.ingest.sentry.io/5599047',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });

  const segmentWriteKey = initAnalytics();

  [
    {
      elementId: 'pf-sign-in',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization showLoading>
            <EnsureUnAuth>
              <SignInForm />
            </EnsureUnAuth>
          </WaitForInitialization>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-sign-up',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization showLoading>
            <EnsureUnAuth>
              <SignUpForm />
            </EnsureUnAuth>
          </WaitForInitialization>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-forgot-password',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization showLoading>
            <EnsureUnAuth>
              <ResetPasswordForm />
            </EnsureUnAuth>
          </WaitForInitialization>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-action',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization showLoading>
            <ActionPage />
          </WaitForInitialization>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-view-quote-confirm',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization showLoading>
            <ViewQuoteRedirectPage />
          </WaitForInitialization>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-my-parts',
      hydrate: () => (
        <AppProviders>
          <RequireAuth>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PartList />
            </motion.div>
          </RequireAuth>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-view-part',
      hydrate: () => (
        <AppProviders>
          <RequireAuth>
            <ViewPartProvider>
              <ViewPart />
            </ViewPartProvider>
          </RequireAuth>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-upload',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization showLoading>
            <UploadPart />
          </WaitForInitialization>
        </AppProviders>
      ),
    },
    {
      elementId: 'globalheader-user-desktop',
      hydrate: () => (
        <AppProviders>
          <Navigation />
        </AppProviders>
      ),
    },
    {
      elementId: 'globalheader-user-mobile',
      hydrate: () => (
        <AppProviders>
          <Navigation mobile />
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-cart',
      hydrate: () => (
        <AppProviders>
          <RequireAuth>
            <Elements stripe={stripe} options={elementsOptions}>
              <Cart />
            </Elements>
          </RequireAuth>
        </AppProviders>
      ),
    },
    {
      elementId: 'pf-cookies-policy',
      hydrate: () => (
        <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
          <CookiePage />
        </Sentry.ErrorBoundary>
      ),
    },
    {
      elementId: 'pf-email-form',
      hydrate: () => (
        <Sentry.ErrorBoundary>
          <EmailListForm />
        </Sentry.ErrorBoundary>
      ),
    },
    {
      elementId: 'pf-flow-cell',
      hydrate: () => (
        <AppProviders>
          <WaitForInitialization>
            <FlowCell />
          </WaitForInitialization>
        </AppProviders>
      ),
    },
  ].forEach(({ elementId, hydrate }) => {
    const elementRoot = document.getElementById(elementId);
    if (elementRoot) {
      elementRoot.innerHTML = '';
      const root = ReactDOM.createRoot(elementRoot);
      root.render(
        <React.StrictMode>
          <Suspense fallback={<Loading />}>
            {hydrate()}
          </Suspense>
        </React.StrictMode>,
      );
    }
  });

  // a special case for the modal
  const modalDiv = document.body.appendChild(document.createElement('div'));
  const root = ReactDOM.createRoot(modalDiv);
  root.render(
    <React.StrictMode>
      <Suspense fallback={<Loading />}>
        <AppProviders>
          <WaitForInitialization>
            <PaywallModal />
          </WaitForInitialization>
        </AppProviders>
      </Suspense>
    </React.StrictMode>,
  );

  // a special case for the consent banner
  const consentDiv = document.body.appendChild(document.createElement('div'));
  consentDiv.id = 'pf-consent-banner';
  const consentRoot = ReactDOM.createRoot(consentDiv);
  window.writeKey = segmentWriteKey;
  consentRoot.render(
    // strict mode doesnt' seem to work for @segment/consent-manager
    // <React.StrictMode>
    <ConsentBanner writeKey={segmentWriteKey} />,
    // </React.StrictMoe>,
  );

  // a special case for testimonials on the home page
  const testimonialsRoot = document.getElementById('pf-testimonials');
  if (testimonialsRoot) {
    // get each testimonial div with the class "testimonial-text"
    const testimonials = [...testimonialsRoot.getElementsByClassName('testimonial-slide')].map((t) => (t.innerHTML));
    ReactDOM.createRoot(testimonialsRoot).render(
      <React.StrictMode>
        <Testimonials testimonials={testimonials} />
      </React.StrictMode>,
    );
  }

  // convert calendly links to popups
  const calendlyLinks = document.querySelectorAll('[pf-calendly-conversion="true"] a, a[pf-calendly-conversion="true"]');
  for (let i = 0; i < calendlyLinks.length; i += 1) {
    const link = calendlyLinks.item(i);
    const calendlyUrl = link.getAttribute('href');
    const buttonText = link.textContent;
    const className = link.getAttribute('class');
    const isCTA = link.getAttribute('pf-is-cta') === 'true';
    link.outerHTML = `<div id="pf-calendly-${i}"></div>`;
    ReactDOM.createRoot(document.getElementById(`pf-calendly-${i}`)).render(
      <React.StrictMode>
        <CalendarButton url={calendlyUrl} text={buttonText} className={className} isCTA={isCTA} />
      </React.StrictMode>,
    );
  }

  const calendlyRawLinks = document.querySelectorAll('[pf-calendly-conversion-raw="true"] a, a[pf-calendly-conversion-raw="true"]');
  for (let i = 0; i < calendlyRawLinks.length; i += 1) {
    const link = calendlyRawLinks.item(i);
    const calendlyUrl = link.getAttribute('href');
    const isCTA = link.getAttribute('pf-is-cta') === 'true';
    const text = link.innerText;
    const className = link.getAttribute('class');
    link.outerHTML = `<div id="pf-calendly-raw-${i}"></div>`;
    ReactDOM.createRoot(document.getElementById(`pf-calendly-raw-${i}`)).render(
      <React.StrictMode>
        <CalendarRawButton
          url={calendlyUrl}
          text={text}
          className={className}
          isCTA={isCTA}
          buttonInnerHtml={link.innerHTML}
        />
      </React.StrictMode>,
    );
  }

  // add tracking to CTA links
  const ctaLinks = document.querySelectorAll('a[pf-is-cta="true"]');
  for (let i = 0; i < ctaLinks.length; i += 1) {
    const link = ctaLinks.item(i);
    link.addEventListener('click', (event) => {
      // Log a "click CTA" event and inserts a timeout of 300 ms to give the track call,
      // mimicking what is done in Analytics.js
      // (https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link)
      event.preventDefault();
      analytics.clickCta({
        cta: link.textContent,
        url: window.location.href.replace(window.location.origin, ''),
      });
      setTimeout(() => {
        window.location.href = link.href;
      }, 300);
    });
  }

  // log meeting scheduled events
  if (window.location.pathname.includes('/meeting-confirmation')) {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('invitee_email');
    const eventName = urlParams.get('event_type_name');
    analytics.meetingScheduled({
      userId_: 'not-used',
      eventName,
      email,
    });
    logLIConverstion(14102522);
  }

  // log get in touch form submissions
  if (window.location.pathname.includes('/contact')) {
    const contactForm = document.querySelector('[pf-contact-form="true"]');
    if (contactForm) {
      contactForm.addEventListener('submit', () => {
        const email = contactForm.querySelector('#email')?.value;
        analytics.submitContactForm({
          userId_: 'not-used',
          email,
        });
        logLIConverstion(14102538);
      });
    }
  }

  // log page view
  analytics.pageView({ customDestinationPageName_: document?.title });
};

window.Webflow.push(window.startReact);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
